import React from 'react';
import PropTypes from 'prop-types';
import s from './RichText.module.scss';

const RichText = ({ html = '', richText = '' }) => (
    <div
        className={s.RichText}
        dangerouslySetInnerHTML={{ __html: html ? html : richText }}
    />
);

RichText.propTypes = {
    html: PropTypes.string,
};

export default RichText;
