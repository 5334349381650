import React from 'react';
import { ucFirst } from '../../utils/caseconverters';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import RichText from '../RichText';
import styles from './TextColumns.module.scss';

const TextColumns = ({ theme = '', title = '', items = [] }) => {
    const classes = classNames(
        styles['Text'],
        styles['Text--' + ucFirst(theme)]
    );
    return (
        <section className={classes}>
            <div className={styles.Text__Inner}>
                {!!title && <h2 className={styles.Text__Title}>{title}</h2>}
                <div className={styles.Text__Container}>
                    {items.map((item, index) => {
                        return <Column key={index} {...item} />;
                    })}
                </div>
            </div>
        </section>
    );
};

TextColumns.propTypes = {
    theme: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            html: PropTypes.string,
        })
    ),
};

const Column = ({ title, text }) => {
    return (
        <div className={styles.Text__Col}>
            {!!title && (
                <h3
                    className={classNames(
                        styles['Text__Title'],
                        styles['Text__Title--Small']
                    )}>
                    {title}
                </h3>
            )}
            <div className={styles.Text__Text}>
                <RichText html={text} />
            </div>
        </div>
    );
};

Column.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

export default TextColumns;
